@font-face {
  font-family: 'Evermore';
  src: url('../app/components/fonts/Evermore.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  /* Largura da barra de rolagem */
}

::-webkit-scrollbar-thumb {
  background-color: #00CD98;
  /* Cor do indicador da barra de rolagem */
  border-radius: 2px;
  /* Borda arredondada do indicador */
}

::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  /* Cor do fundo da barra de rolagem */
}

.ql-toolbar {
  border-bottom: 1px solid #ccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ql-toolbar.ql-snow {
  border-color: #45484C !important;
}

.ql-container {
  min-height: 150px;
  max-height: 150px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ql-container.ql-snow {
  border-color: #45484C !important;
}

.ql-editor {
  min-height: 150px;
  max-height: 150px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}